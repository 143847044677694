<template>
  <div class="history">
    <el-table border :data="list" :row-style="{ height: 0 }" :cell-style="{ padding: '10px 0'}">
      <el-table-column prop="tempId" label="ID" align="center" width="45"></el-table-column>
      <el-table-column prop="channelName" label="渠道名称" align="center"></el-table-column>
      <el-table-column prop="begin_at" label="开启时间" align="center"></el-table-column>
      <el-table-column prop="buy_count" label="计划数量(人)" align="center"></el-table-column>
      <el-table-column prop="used_count" label="已推广人数" align="center"></el-table-column>
      <el-table-column prop="amount" label="消费星币(个)" align="center">
        <template slot-scope="scope">{{scope.row.amount ? (scope.row.amount/100) : 0}}</template>
      </el-table-column>
      <el-table-column prop="status" label="状态" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.status | statusFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="op" label="操作"  align="center" width="130px">
        <template slot-scope="scope">
          <!-- 查看 -->
          <el-button
            type="danger"
            size="mini"
            icon="el-icon-search"
            class="view-btn"
            circle
            @click="$emit('record', scope.row.id)"
          ></el-button>
           <el-button
              type="info"
              size="mini"
              icon="el-icon-mobile"
              circle
              @click="$emit('cond', scope.row.cond_description)"
          ></el-button>
          <!-- 继续推广 -->
          <el-button
            v-if="scope.row.status == 2"
            type="success"
            size="mini"
            icon="el-icon-video-play"
            circle
            @click="$emit('resumeRecord', scope.row.id)"
          ></el-button>
          <!-- 暂停推广 -->
          <el-button
            v-if="scope.row.status == 1"
            type="warning"
            size="mini"
            icon="el-icon-video-pause"
            circle
            @click="$emit('pauseRecord', scope.row.id)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  filters: {
    statusFilter(status) {
      switch (status) {
        case -1:
          return '已取消';
        case 0:
          return '待确认';
        case 1:
          return '推广中';
        case 2:
          return '暂停推广';
        case 99:
          return '推广完成';

      }
    }
  }
};
</script>

<style lang="scss" scoped>
.history {
  margin-top: 20px;
  padding: 0 30px;
}
</style>
