<template>
  <div class="form">
    <el-form
      class="promote-setting-form"
      ref="form"
      :rules="rules"
      :model="form"
      label-width="140px"
      size="medium"
      hide-required-asterisk
    >
      <el-form-item label="推广渠道" prop="channelId">
        <el-select v-model="form.channelId" placeholder="请选择推广渠道">
          <el-option
            v-for="item in channels"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="推广开启时间" prop="beginTime">
        <el-date-picker
          v-model="form.beginTime"
          type="datetime"
          placeholder="选择开始时间"
          format="yyyy-MM-dd H时"
          value-format="yyyy-MM-dd HH:00:00"
        ></el-date-picker>
        <span class="tip ft-sz-13 c-grey">此处仅设置推广计划开启时间，结束时间以计划完成为准</span>
      </el-form-item>
      <el-form-item label="计划推广数量" prop="count">
        <el-input-number
          v-model="form.count"
          :min="50"
          :max="100"
          :step="10"
          :step-strictly="true"
          @change="previewPrice"
          @blur="previewPrice"
        ></el-input-number>
        
          <!--  -->
        <span class="tip ft-sz-13 c-grey">数量越多，享受的优惠力度越大</span>
      </el-form-item>
      <el-form-item
        v-for="(config, index) in configList"
        :key="index"
        :label="config.name"
        :prop="config.code"
      >
        <template v-if="config.type === 'radio'">
          <el-radio
            v-for="(r, i) in config.enum"
            :key="i"
            v-model="form[config.code]"
            :label="r.value"
            @change="previewPrice(config.code)"
          >{{r.name}}</el-radio>
        </template>
        <span class="tip ft-sz-13 c-grey">{{ config.description }}</span>
      </el-form-item>
      <el-form-item label="推广计划费用统计:">
        <span class="c-red">
          <span class="ft-sz-22 ft-bold">{{ sumPrice }}</span>
          星币
        </span>
      </el-form-item>
      <div>
        <el-button type="primary" @click="submit">开始推广</el-button>
        <el-button class="charge-btn" @click="toCharge">立即充值</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import service from '../api';
export default {
  data() {
    return {
      form: { beginTime: '', count: 50, zhima: 0, channelId: '' },
      rules: {
        channelId: [{ required: true, pattern: /^\d+$/, message: '请选择推广渠道', blur: true }],
        beginTime: [{ required: true, message: '请选择推广时间', blur: true }],
      },
      configList: [],
      channels: [],
      sumPrice: 0
    };
  },
  methods: {
    // 获取配置
    getConfig() {
      service.channel({
        status: 0,
        pageNum: 1,
        pageSize: 99999,
      }).then(res => {
        this.channels = res.list.map(r => ({id: r.id, name: r.name}))
      })
      return service.config().then(res => {
        this.configList = res;
        this.configList.forEach(item => {
          switch (item.type) {
            case 'radio':
              this.form[item.code] = item.value;
              break;
          }
        })
        return this.previewPrice();
      });
    },
    toCharge() {
      this.$router.push({ path: '/charge', query: { type: 1 } });
    },
    // 预览价格
    previewPrice(type) {
      let { configList = [], form = {} } = this;
      if (type) {
        configList.map(c => {
          if (c.code === type) c.value = form[c.code];
          return c;
        });
      }
      return service
        .preview({ count: form.count, data: JSON.stringify(configList) })
        .then(res => (this.sumPrice = res.amount / 100));
    },
    submit() {
      let check = true;
      this.$refs['form'].validateField(['channelId','beginTime'], res => (check = !res));
      if (!check) return;
      let params = {...this.form, data: JSON.stringify(this.configList) };
      this.$emit('submit', params);
      this.$refs['form'].resetFields();
    }
  },
  mounted() {
    this.getConfig();
  }
};
</script>

<style lang="scss" scoped>
.form {
  padding: 40px 30px;
  background-color: #fff;
  .el-form {
    text-align: left;
    .el-input {
      width: 200px;
    }
  }
  .tip {
    margin-left: 20px;
  }
  .charge-btn {
    background-color: #f93b7a;
    border: 1px solid #f93b7a;
    color: #fff;
  }
}
@media screen and (max-width: 480px) {
.form .tip{
  display: block;
}
}
</style>
