<template>
  <div class="records">
    <el-dialog
      title="推广记录"
      width="70%"
      :visible.sync="isShow"
      :modal="false"
      :close-on-click-modal="false"
      @close="$emit('cancel')"
      @open="openDialog"
    >
      <el-table border :data="records" :row-style="{ height: 0 }" :cell-style="{ padding: '10px 0'}">
        <el-table-column prop="tempId" label="ID" align="center" width="80"></el-table-column>
        <el-table-column prop="name" label="姓名" align="center"></el-table-column>
      <el-table-column prop="mobile" label="手机号码" align="center"></el-table-column>
      <el-table-column prop="idcard" label="身份证号" align="center"></el-table-column>
      <el-table-column prop="create_at" label="推广时间" align="center"></el-table-column>
      <el-table-column prop="bills" label="消耗星币" align="center"  width="100px">
        <template slot-scope="scope">
          <!-- 查看 -->
          <el-tag v-if="scope.row.billing" type="success">消耗</el-tag>
          <el-tag v-if="!scope.row.billing" type="info">不消耗</el-tag>

        </template>
      </el-table-column>
      <el-table-column prop="op" label="操作" width="80px" align="center">
        <template slot-scope="scope">
          <!-- 查看 -->
          <el-button
            type="danger"
            size="mini"
            icon="el-icon-search"
            class="view-btn"
            circle
            @click="$emit('view', scope.row, false)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :page-sizes="pageSizes"
      :page-size="10"
      :current-page="currentPage"
      layout="total, sizes, prev, pager, next"
      :total="page.allNum"
      @size-change="changeSize"
      @current-change="changePage"
    ></el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import service from '../api'

import pagination from '@/mixins/pagination';
export default {
  mixins: [pagination],
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    promotionId: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      records: [],
      page: {},
    }
  },
  methods: {

    getList(params) {
        // 切换分页当前页码
        let { pageNum = 1 } = params;
        this.currentPage = pageNum;

        return service.record({
          promotionId: this.promotionId,
          pageNum: this.currentPage,
          pageSize: 10,
        }).then(res => {
          let { size = 10, pages = [], total = 0, records = [] } = res;
          this.page = { allNum: total, pageSize: size, allPage: pages };

          this.records = res.records.map(r => ({userId: r.user_id, ...r}));
          this.records.forEach((item, index) => {
            item.tempId = (params.pageNum - 1) * this.page.pageSize + index + 1;
          });
      })

    },
    openDialog() {
     this.getList({ pageNum: 1 })
    }
  }
}
</script>

<style scoped>

</style>
