import API from "@/api";

export default {
  // 提交推广
  channel: params => {
    return API.requestGet({
      url: "link/getLink",
      params
    });
  },
  // 获取推广配置
  config: () => {
    return API.requestGet({
      url: "promotion/config"
    });
  },
  // 预览价格
  preview: params => {
    return API.requestPost({
      url: "promotion/preview",
      data: params
    });
  },
  // 提交推广
  submit: params => {
    return API.requestPost({
      url: "promotion/request",
      data: params
    });
  },
  // 推广历史
  history: (params) => {
    return API.requestGet({ url: "promotion/list" , params}

    );
  },
  // 推广记录
  record: (params) => {
    return API.requestGet(
      { url: "promotion/record" , params}
    );
  },
  //继续推广
  resumeRecord: params => {
    return API.requestPost({
      url: "promotion/resume",
      data: params
    });
  },
  //暂停推广
  pauseRecord: params => {
    return API.requestPost({
      url: "promotion/pause ",
      data: params
    });
  }
};
